<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col>
        <h2>{{ $t(`My Projects`) }}</h2>
        <p>
          <span v-for="profile in userInfo.enabled_profiles" :key="profile">
            {{ profile }}
          </span>
        </p>
      </v-col>
      <v-col>
        <v-btn-toggle
          class="styled-btn-group"
          v-model="activeBtn"
          rounded
          mandatory
          active-class="active-tab-btn"
        >
          <v-btn
            :outlined="activeBtn !== 'orders'"
            color="primary"
            value="orders"
            to="/projects/orders/new"
          >
            {{ $t("My Orders") }}
          </v-btn>
          <v-btn
            :outlined="activeBtn !== 'bids'"
            color="primary"
            value="bids"
            to="/projects/bids/new"
          >
            {{ $t("My Jobs") }}
          </v-btn>
          <v-btn
            :outlined="activeBtn !== 'archive'"
            color="primary"
            value="archive"
            to="/projects/archive"
          >
            {{ $t("Archive") }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-window v-model="activeBtn" class="pa-4">
      <router-view />
    </v-window>
  </v-container>
</template>

<script>
import { getUserInfo } from "@/auth/auth";
import { includes } from "lodash";
const PER_PAGE = 15;

export default {
  data: () => ({
    perPage: PER_PAGE,
    activeBtn: "orders",
    statusTab: "New",
    newOrderPage: 1,
    newBidPage: 1,
    inProgressOrderPage: 1,
    doneOrderPage: 1,
    bidsItems: {
      new: [],
      in_progress: [],
      done: []
    },
    ordersItems: {
      new: [],
      in_progress: [],
      done: []
    },
    userInfo: getUserInfo() || {}
  }),
  computed: {
    myActiveButton: {
      get() {
        return this.activeBtn;
      },
      set() {
        if (includes(this.userInfo.enabled_profiles, "customer")) {
          return "orders";
        } else {
          return "bids";
        }
      }
    }
  }
};
</script>

<style lang="scss">
.styled-btn-group.v-btn-toggle {
  margin: 0 auto;
  border: 1px solid #005fce;
  &--rounded {
    .v-btn {
      border: 0;
      border-left: 0;
      border-right: 0;
      border-color: transparent !important;
      color: #000;
      padding-right: 3rem;
      padding-left: 3rem;
      font-size: 20px;
    }
  }
}

.no-border.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: transparent !important;
  border-style: solid;
  border-width: thin 0 0 0;
}
</style>
